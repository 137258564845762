.main-container {
    padding: 0px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .source-form-wrapper {
        width: 600px;
    }
}

.form-label {
    color: #1F2747;
    font-weight: 500;

    svg {
        color: #4B5168;
    }
}

.form-control {
    height: 36px;
    padding: 8px 16px;
    color: #1F2747;
    background: #FFFFFF;
    border: 1px solid #C6C8CE;
    box-shadow: inset 0px 2px 4px rgb(31 39 71 / 10%);
    border-radius: 8px;
    transition: none;

    &:hover {
        border: 1px solid #4B5168;
    }

    &:focus {
        border: 1px solid #6C89E8;
        outline: #FFFFFF;
        box-shadow: none;
    }
}

.form-check {
    display: flex;
    margin-bottom: 4px !important;

    .form-check-label {
        align-self: center;
        margin-left: 8px;
    }

}

.text-heading {
    font-weight: 600;
    height: 17px;
    line-height: 17px;
    color: #1F2747;
    margin-bottom: 4px;
}

.heading1 {
    font-weight: 500;
    height: 17px;
    line-height: 17px;
    color: #1F2747;
    margin-bottom: 8px;
}

.heading2 {
    color: #4B5168;
    line-height: 18px;
}

.button-delete {
    background: #D13E2C;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #DA6556;
    }
}

.button-primary {
    background: #234FDC;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #476CE2;
    }
}

.inactiveButton {
    opacity: 0.5;
}

.button-secondary {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
    }
}

.hero-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1F2747;
    margin-bottom: 16px;
}

// source form

.source-creation-wrapper {
    width: 600px;
    padding: 36px;
    margin: 0 auto;

    >div:not(:last-child) {
        margin-bottom: 36px;
    }

    .hero-wrapper {
        .tab-button {
            font-weight: 600;
            padding: 8px 16px;
            background: #EDF0FC;
            border-radius: 8px;
            border: none;
            margin-top: 24px;
            color: #234FDC;
        }
    }

    .hero-text-wrapper {
        display: flex;
        flex-direction: column;

        .hero-text {
            div:first-child {
                color: #1F2747;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
            }

            span {
                color: #4B5168;

            }
        }
    }

    .sourceIconsContainer {
        display: flex;
        flex-direction: column;

        .content {
            display: flex;

            div {
                &:hover {
                    border: 1px solid #4B5168;
                }

                border: 1px solid #C6C8CE;
                box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
                border-radius: 8px;
                height: auto;
                cursor: pointer;

                img {
                    display: block;
                    width: 36px;
                    height: auto;
                    padding: 4px;
                }
            }

            >div:not(:last-child) {
                margin-right: 8px;
            }
        }

        .icon-select-class {
            background: #EDF0FC;
            border: 1px solid #234FDC !important;
        }

    }

    .source-form-wrapper {
        position: relative;
        border-radius: 8px;

        >div:not(:last-child) {
            margin-bottom: 16px;
        }

        .checkbox-text-style {
            display: flex;
            flex-direction: column;

            >span {
                margin-left: 20px;
            }
        }

        .source-help {
            position: absolute;
            top: 137px;
            right: 5px;

            label {
                a {
                    font-weight: 400;
                    color: #476CE2;
                }
            }
        }
    }

    .source-form {
        margin-bottom: 16px;

        >div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

    }

    .button-wrapper {
        display: flex;
        flex-direction: row-reverse;

        >button:not(:last-child) {
            margin-left: 8px;
        }
    }

}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.input-wrapper {
    .instruction {
        min-height: 60px;
        height: auto;
        overflow-y: auto;
    }
}

.file-dir-select {
    height: 200px;
    padding: 8px 16px;
    color: #1F2747;
    background: #FFFFFF;
    border: 1px solid #C6C8CE;
    box-shadow: inset 0px 2px 4px rgb(31 39 71 / 10%);
    border-radius: 8px;
    overflow-y: auto;

    >div {
        padding: 4px 0px;
    }

    &:hover {
        border: 1px solid #4B5168;
    }

    .selection-item {
        margin-bottom: 8px;
    }
}

input[type=file]::file-selector-button {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
        transition: none;
    }
}

.handle-file-buttons {
    display: flex;
    margin-top: 8px;

    >*:not(:last-child) {
        margin-right: 8px;
    }
}

.button-secondary.disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}


.button-secondary-leave .inner-button {
    display: inline-block;
    background-color: white; /* or any color you prefer for the inner button */
    color: #E60000; /* Text color of the inner button */
    padding: 5px 10px;
    margin-right: 10px; /* Space between inner button and main button text */
    border: 1px solid #E60000; /* Border color of the inner button */
    border-radius: 5px; /* Rounded corners for the inner button */
    
}
.button-secondary-leave {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
    }
    display: inline-flex;
    align-items: center;
    padding: 10px 15px; /* Adjust as necessary for your design */
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-secondary-leave.disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}

.arrow-container {
    display: flex;
    align-items: center;
    margin-left: -5px;      /* Negative margin to merge the shaft with the arrowhead */
    margin-right: 5px;      /* Negative margin to merge the shaft with the arrowhead */
}

.fa-chevron-left {
    font-weight: 160;  /* Adjust as necessary, the lower the value the thinner the icon */
}


.arrow-shaft {
    width: 13px;            /* Length of the arrow shaft */
    height: 2px;            /* Thickness of the arrow shaft */
    background-color: white; /* Color of the arrow shaft to match the button text */
    margin-left: -5px;  /* Space between the arrow and the text "Leave Space" */
}

.input-boxes-for-roomOS {
    display: flex;  
    justify-content: space-evenly;
    align-items: center;
    height: 100px;
    
}

.input-box-for-roomOS {
    width: 50px; /* Increased width */
    height: 50px; /* Increased height */
    font-size: 24px; /* Make the font size bigger */
    text-align: center;
    border: 2px solid #333; /* Thicker border */
    border-radius: 5px; /* Slightly increased border radius */
    background-color: #fff; /* Optional: to ensure a white background */
}


/* Sidebar Styles */
.sidebar {
    width: 450px; /* Adjusted width to account for increased padding */
    background-color: #FFFFFF;
    padding: 24px 18px; /* Increased padding */
    position: fixed;
    right: 20px;
    top: 20px;
    overflow: hidden;
    z-index: 1000;

    span {
        color:#555555
    }

    // .field {

    // }
}

/* Sidebar Header */
.sidebar h2 {
    font-size: 20px; /* Increased font size */
    font-weight: bold; /* Made it bold */
    margin-bottom: 16px; /* Increased space below header */
}

.sidebar p {
    color: #555555;
    font-size: 14px; /* Increased font size */
    line-height: 1.5; /* Increased line height for better readability */
    margin-bottom: 20px;
}

.conference-container {
    padding: 20px;
    border-radius: 8px;
    width: 450px; // Adjust this width based on your requirement
    background-color: #FFFFFF;
}

.field-group {
    margin-bottom: 20px;

    label {
        display: block;
        margin-bottom: 10px;
    }

    /* Targeting the container */
    .react-select__control {
        width: 100%;
        padding: 10px;
        border: 1px solid #C6C8CE;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        background-color: #FFFFFF;
        font-size: 16px;

        &:hover {
            border-color: #C6C8CE; 
        }

        &:focus-within {
            outline: none;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }
    }

    /* Targeting the dropdown options */
    .react-select__option {
        padding: 10px 15px;
        border-bottom: 1px solid #EAEAEA; 
        border-radius: 5px;

        &:last-child {
            border-bottom: none;
        }

        &:hover, &.react-select__option--is-focused {
            background-color: #f3f3f3;
        }
    }

    input {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
}


.button-container {
    display: flex;
    justify-content: space-between; // Spacing between 'End Conferencing' and the grouped 'Cancel' and 'Save' buttons
    align-items: center;
    gap:3px;
    

    .group-right {
        display: flex;
        gap: 10px; // Spacing between 'Cancel' and 'Save' buttons
    }

    .button-secondary {
        background: #F3F4F5;
        border-radius: 8px;
        color: #1F2747;
        border: 1px solid #C6C8CE;
        height: 36px;
        padding: 0px 16px;
        font-weight: 600;

        &:hover {
            background: #EAEBEE;
        }
    }

    .button-conferencing {
        background: #F3F4F5;
        border-radius: 8px;
        color: #1F2747;
        border: 1px solid #C6C8CE;
        height: 36px;
        padding: 0px 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
    
        /* Icon styling */
        svg {
            fill: #1F2747;
            width: 20px;
            height: 20px;
        }

        img {
            fill: #1F2747;
            width: 20px;
            height: 20px;
        }
    
        /* Hover effect */
        &:hover {
            background: #EAEBEE;
    
            svg {
                fill: #1F2747; /* assuming you want the icon color to remain the same on hover */
            }
        }
    }

    .button-connected {
        background: rgba(35, 79, 220, 0.15); /* Deep blue color */
        border-radius: 8px;
        color: #2554f0; /* Text color */
        border: 1px solid #C6C8CE;
        height: 36px;
        padding: 0px 16px;
        padding-top: 0px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        transition: background-color 0.3s;
        border: none; /* Remove border */
    
        /* Icon styling */
        img {
            fill: white; /* Icon color to match the text color */
            width: 20px;
            height: 20px;
        }
    
        /* Hover effect */
        &:hover {
            background: rgba(0, 60, 255, 0.432); /* Slightly darker blue color on hover */
            
            img {
                fill: white; /* Keeping the icon color consistent on hover */
                width: 20px;
                height: 20px;
            }
        }
    }
    
    

    .button-primary {
        
        background-color: #007BFF;
        color: #FFFFFF;
        padding: 10px 20px; // Adjust as needed to match other buttons
        border: none;
        border-radius: 8px;
        height: 36px;
        padding: 0px 16px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background-color: #0069D9;
        }
    }

    .button-third {
        background-color: #E60000;
        color: #FFFFFF;
        padding: 10px 20px; // Adjust as needed to match other buttons
        border: none;
        border-radius: 8px;
        height: 36px;
        padding: 0px 16px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background-color: #b80000;
        }
    }
}











@-moz-document url-prefix() {
    .source-help {
        position: absolute !important;
        top: 160px !important;
        right: 5px !important;
    }
}