:root {

    --userful-primary-dark: #1F2747;
    --userful-primary-light: #4B5168;
    --userful-primary-blue: #234FDC;
    --userful-primary-blue-dark: #1C3FB0;
    --userful-primary-blue-light: #EDF0FC;
    --userful-secondary: #F3F4F5;
    --userful-secondary-dark: #EAEBEE;
    --userful-gray-dark: #9498a3;
    --userful-green: #23A866;
    --userful-green-dark: #1A7E4D;
    --userful-green-light: #D5F6E6;
    --userful-orange: #FFAD1E;
    --userful-orange-light: #FFFBD8;
    --userful-alert: #D13E2C;
    --userful-alert-dark: #A73223;
    --userful-alert-light: #FAECEA;
    --userful-white: #FFFFFF;

    --userful-bg-primary: var(--userful-primary-blue);
    --userful-bg-primary-hover: var(--userful-primary-blue-dark);
    --userful-bg-primary-disabled: var(--userful-primary-blue);
    --userful-bg-primary-focus: var(--userful-primary-blue);
    --userful-bg-primary-light: var(--userful-primary-blue-light);
    --userful-bg-secondary: var(--userful-secondary);
    --userful-bg-secondary-hover: var(--userful-secondary-dark);
    --userful-bg-secondary-disabled: var(--userful-secondary);
    --userful-bg-success: var(--userful-green);
    --userful-bg-success-hover: var(--userful-green-dark);
    --userful-bg-alert: var(--userful-alert);
    --userful-bg-alert-hover: var(--userful-alert-dark);
    --userful-bg-alert-disabled: var(--userful-alert);
    --userful-bg-alert-focus: var(--userful-alert);

    --userful-border-secondary: #C6C8CE;
    --userful-border-primary: var(--userful-primary-blue);

    --userful-bg-doc-get: #63a8e2;
    --userful-bg-doc-post: #6cbd7d;
    --userful-bg-doc-delete: #d26460;
    --userful-bg-doc-put: #22bac4;
    --userful-doc-primary: #337ab7;



}