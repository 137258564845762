.source-form-wrapper {


    .source-form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        background: #FFFFFF;
    }

    .videoConferencingContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .disabled {
                opacity: 0.5;
                border: 1px solid #C6C8CE !important;
                background-color: #FFFFFF !important;
                cursor: default !important;
            }

            >.videoIconWrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 136px;
                height: 130px;
                background: #EDF0FC;
                border: 1px solid #234FDC;
                box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
                border-radius: 8px;
                cursor: pointer;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                }

                span {
                    color: #1F2747;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
}

.label-with-counter {
    display: flex;
    justify-content: space-between;
    /* Adjust the spacing between the label and counter */
    align-items: center;
    /* Vertically center the items */
    width: 100%;
    /* Use the full width of the parent */
    // margin-bottom: 10px; /* Optional: Add some space between the label and the input */
}

.heading1,
.label-with-counter>div {
    margin-right: 10px;
    /* Spacing between label and counter */
}

.sourceFormWrapper {
    display: flex;
    max-width: 480px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 16px;

    .sourceFormHeader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        >.sourceFormHeaderTitle {
            align-self: stretch;

            color: var(--dark-400, #1F2747);
            text-align: center;
            font-family: Inter;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            cursor: default;
        }

        >.sourceFormHeaderDescription {
            align-self: stretch;

            color: var(--dark-100, #4B5168);
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            cursor: default;
        }


    }



}

.sourceFormBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;


    .sourceFormBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        >.sourceFormBlockHeader {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            justify-content: space-between;

            width: 100%;

            >.sourceFormBlockHeaderBlock {
                display: flex;
                align-items: center;
                gap: 4px;

                >.sourceFormBlockHeaderTitle {
                    color: var(--dark-400, #1F2747);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                >.sourceFormBlockHeaderDescription {
                    color: var(--dark-100, #4B5168);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    /* 128.571% */
                }

                >.sourceFormBlockHeaderCount {
                    color: var(--dark-100, #4B5168);
                    text-align: right;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

    }
}



.inputField {
    display: flex;
    height: 36px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--light-500, #C6C8CE);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.10) inset;

    &:focus {
        border-radius: 8px;
        border: 1px solid var(--primary-500, #6C89E8);
        background: #FFF;
        outline: none;
    }

    &:hover {
        border-radius: 8px;
        border: 1px solid var(--dark-100, #4B5168);
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.10) inset;
    }
}


.sourceFromBlockButton {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--primary-700, #234FDC);

    color: #FFF;

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    cursor: pointer;

    &:hover {
        background: var(--primary-800, #1C3FB0);
    }

    .arrowIconRigth {
        text-align: center;
        font-size: 16px;
        font-weight: 900;
    }
}