.screenVideo {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.screenDiv {
    border: 1px solid #121212;
    padding: 8px;
    display: flex;
    flex-direction: column;

    .profile {
        display: flex;
        align-items: center;
        gap: 4px;


        .circle {
            width: 32px;
            height: 32px;
            border-radius: 99px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #363636;
            font-size: 16px;
            color: #ffffff;
            font-weight: 600;
        }

        .guestNameDiv {
            display: flex;
            height: 28px;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 8px;
            background-color: #2E2E2E;
            color: #ffffff;
            text-align: center;
        }
    }



    .abbreDiv {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .abbreDivTotal {
            display: flex;
            flex-direction: row;
            gap: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }

    }

}

.abbreName {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    border-radius: 99px;
    background-color: #363636;
}

.abbreNameSmall {

    width: 60px !important;
    height: 60px !important;

}

.presentPanel {
    display: flex;
    overflow: hidden;
    background-color: var(--dark-theme-500, #121212);

    .layout {
        // height: calc(100vh - 168px) !important;
        margin: unset !important;
    }

}

.presentPanel3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.shared-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.all-content {
    display: flex;
    width: 762px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.shared-content p {
    color: #C6C8CE;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
}

.shared-content-label {
    color:  #C6C8CE;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
}

.sharedImagesDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    width: 240px;

    img {
        width: 250px;
        height: 222.321px;
    }

    .imageWrapDiv {
        display: flex;
        width: 120px;
        height: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 96px;
            height: 80px;
        }
    }
}

.hidden {
    display: none;
}