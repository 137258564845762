.modal-content {
    // box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14) !important;
    // border-radius: 8px !important;
    // border: 1px solid #C6C8CE !important;
    padding: 16px;
    // background-clip: unset !important;
    margin: 0 auto;
    max-width: 500px;

    .modal-header-title {
        margin-bottom: 0px;

        .modal-header {
            border-bottom: none;
            padding: 0px;
            margin-bottom: 8px;
            color: #1F2747;
            font-size: 18px;
            font-weight: 600;
            padding: 0px !important;

            .modal-title {
                display: flex;
                flex-direction: column;
                font-weight: 600;
            }

            .modal-close-btn {
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    border-radius: 8px;
                    background: #F3F4F5;
                }

                .custom-close-btn {
                    height: 16px;
                    width: auto;
                    padding: 11px 15px;
                }
            }
        }
    }

    .modal-header-description {
        color: #1F2747;
        font-weight: 400;
    }

    .modal-body {
        padding: 0px !important;
        color: #4B5168;
        margin-bottom: 24px;

        .form-check {
            color: #1F2747;
        }

        .input-wrapper:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .modal-footer {
        padding: 0px !important;
        border-top: none !important;
    }

    .modal-header>h5 {
        display: flex;
        color: #1F2747;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
}

.modal-form-check {
    color: #1F2747;
}