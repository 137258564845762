* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html,
body {
    font-family: Inter, sans-serif;
    height: 100%;
    width: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.App {
    height: 100%;
}

.fullscreen {
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.order1 {
    order: 1;
}

.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-content {
    width: 100%;
    height: calc(100% - 56px);
    display: flex;
}

.active {
    background: #EDF0FC;
}

.flex {
    display: flex;
    align-items: center;
}

.hero-wrapper {
    >div {
        display: flex;
        justify-content: space-between;
    }

    .tab-button {
        font-weight: 600;
        padding: 8px 16px;
        background: #EDF0FC;
        border-radius: 8px;
        border: none;
        margin-top: 24px;
        color: #234FDC;
    }

    .hero-text-wrapper {
        width: 460px;
        max-width: 460px;
    }

    .custom-close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        cursor: pointer;

        &:hover {
            border-radius: 8px;
            background: #F3F4F5;
        }
    }
}

.react-calendar {
    border-radius: 8px;
}

input.react-datetimerange-picker__inputGroup__input {
    &:focus-visible {
        outline: none;
    }
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
    // background: #EDF0FC !important;
    // border-radius: 8px;
}

.offcanvas.show {
    width: 600px !important;
    overflow-y: auto;
}

.offcanvas-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px;
}

.main-container {
    padding: 0px;
    width: 100%;
}

.success-btn {
    font-weight: 600;
    padding: 0px 16px;
    max-height: 36px;
    outline: none;
    border: none;
    background: #23A866;
    border-color: #23A866;
    border-radius: 8px;
    color: #FFFFFF;

    &:hover {
        background: #2CD280;
        border-color: #2CD280;
    }
}

// .primary-btn {
//     font-weight: 600;
//     padding: 0px 16px;
//     max-height: 36px;
//     background: #234FDC;
//     border-radius: 8px;
//     &:hover {
//         background: #476CE2;
//         border-color: #476CE2;
//     }
// }

.primary-btn {
    background: #234FDC;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #476CE2;
    }
}

.secondary-btn {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
    }
}

.button-danger {
    font-weight: 600;
    padding: 0px 16px;
    background: #D13E2C;
    outline: none;
    border: none;
    color: #FFFFFF !important;
    max-height: 36px;
    height: 36px;
    color: #1F2747;
    border-radius: 8px;

    &:hover {
        background: #DA6556;
    }
}

.pagination {
    .page-item {
        // border: 1px solid #C6C8CE;
        // border-radius: 8px;
    }

    .page-item.active {
        .page-link {
            background: #234FDC;
            border: 1px solid #234FDC;
            box-sizing: border-box;
            border-radius: 8px;
        }
    }
}

.table-actions {
    .table-actions-left {
        .dropdown {
            border-radius: 8px;
            cursor: pointer;

            >div {
                border: 1px solid #C6C8CE;
            }
        }

    }
}

.dropdown {
    cursor: pointer;

    .custom-dropdown-menu {
        padding: 8px 0px;
        background: #FFFFFF;
        border: 1px solid #C6C8CE;
        box-sizing: border-box;
        box-shadow: 0px 8px 10px rgb(0 0 0 / 14%);
        border-radius: 8px;
        margin-top: 2px;

        ul {
            padding: 0px;
            margin: 0px;

            .dropdown-item {
                color: #1F2747;
                display: flex;
                align-items: center;
                padding: 0px 16px;
                height: 32px;
                cursor: pointer;

                &:hover {
                    background: #F3F4F5;
                }

                &:active {
                    background: #F3F4F5;
                }

                svg,
                img {
                    margin-right: 16px;
                }
            }
        }

        hr {
            margin: 8px 0px;
        }

    }
}

.custom-dropdown-toggle {
    background: #F3F4F5;
    border-radius: 8px;
    padding: 0px 16px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1F2747;
    font-weight: 600;

    &:hover {
        background-color: #EAEBEE;
    }
}

.dropdown {
    .dropdown-toggle::after {
        display: none !important;
    }
}

input[type="checkbox"] {
    border: 1px solid #C6C8CE;
    box-sizing: border-box;
    box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1);
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

input[type="checkbox"]:checked {
    background-color: #234FDC !important;
    color: #FFFFFF;

    &:focus {
        box-shadow: none;
        border: 1px solid #C6C8CE !important;
    }
}

.form-check-input:focus {
    border: 1px solid #C6C8CE !important;
    outline: none !important;
    box-shadow: none !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    color: #476CE2;
    cursor: pointer;
}

.info-heading {
    font-weight: 600;
    color: #1F2747;
    margin-bottom: 4px;
}

.toggle-switch {
    >:nth-child(2) {
        height: 12px !important;
        width: 12px !important;
        top: 2px !important;
    }
}

.source-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 320px);
    padding: 16px;
    position: relative;

    >div:not(:last-child) {
        margin-bottom: 16px;
    }

    .table-actions {
        display: flex;
        justify-content: space-between;

        .table-actions-left {
            display: flex;

            >div:not(:first-child) {
                margin-left: 8px;
            }
        }

        .table-actions-right {
            display: flex;

            >button:not(:first-of-type) {
                margin-left: 8px;
            }
        }

        .server-options {
            min-width: 229px;

            >div {
                padding: 0px 16px;
            }
        }
    }

    .bread-crumb {
        a {
            text-decoration: none;
            color: #476CE2;
            font-weight: 500;
        }
    }

    .breadcrumb-item.active {
        color: #1F2747;
        font-weight: 500;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        font-weight: 500;
    }


}

.dropdown {
    cursor: pointer;

    .custom-dropdown-menu {
        padding: 8px 0px;
        background: var(--userful-white);
        border: 1px solid var(--userful-border-secondary);
        box-sizing: border-box;
        box-shadow: 0px 8px 10px rgb(0 0 0 / 14%);
        border-radius: 8px;
        margin-top: 2px;

        ul {
            padding: 0px;
            margin: 0px;

            .dropdown-item {
                color: var(--userful-primary-dark);
                display: flex;
                align-items: center;
                padding: 0px 16px;
                height: 32px;
                cursor: pointer;

                &:hover {
                    background: var(--userful-secondary);
                }

                &:active {
                    background: var(--userful-secondary);
                }

                svg,
                img {
                    margin-right: 16px;
                }
            }
        }

        hr {
            margin: 8px 0px;
        }

    }
}

.custom-dropdown-toggle {
    background: var(--userful-secondary);
    border-radius: 8px;
    padding: 0px 16px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--userful-primary-dark);
    font-weight: 600;
    gap: 8px;

    &:hover {
        background-color: var(--userful-secondary-dark);
    }
}

.dropdown {
    .dropdown-toggle::after {
        display: none !important;
    }
}

.custom-dropdown-menu {
    .dropdown-item {
        width: 275px;

        &:hover {
            background-color: var(--userful-secondary) !important;
            border: unset !important;
            color: unset !important;
        }


    }

    .dropdown-item.active {
        border: unset !important;
        background-color: var(--userful-secondary) !important;
        color: var(--userful-primary-dark) !important;
        font-weight: 500;
    }
}