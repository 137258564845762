.pairingCodeWrapper {

    display: flex;
    justify-content: center;
    width: 100%;
    gap: 8px;

    .pairingCodeField {
        display: flex;
        height: 56px;
        width: 48px;

        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        justify-content: center;
        border: 1px solid var(--light-500, #C6C8CE);
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.10) inset;

        color: var(--dark-400, #1F2747);
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:focus {
            border-radius: 8px;
            border: 1px solid var(--primary-500, #6C89E8);
            background: #FFF;
            outline: none;
        }

        &:hover {
            border-radius: 8px;
            border: 1px solid var(--dark-100, #4B5168);
            background: #FFF;
            box-shadow: 0px 2px 4px 0px rgba(31, 39, 71, 0.10) inset;
        }
    }
}

.disabledButton {
    opacity: 50%;
    pointer-events: none;
    cursor: default !important;
}