.userManagementTextWidget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    .userManagementTextWidgetHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #1F2747 !important;
        width: 100%;

        .userManagementTextWidgetHeaderOne {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 4px;

            .problemCanvasTitleOne {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                display: flex;
                align-items: center;
                color: #1F2747;
            }

            .problemCanvasQuestionIconOne {
                width: 14px;
                height: 14px;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #4B5168;

            }
        }
    }


    .formcontrolTextWidget {
        width: 100%;

        .form-control {
            // height: 36px !important;
            border: 1px solid #C6C8CE !important;
            border-radius: 8px !important;
            box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1) !important;
            padding: 8px 16px 8px 16px !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #1F2747 !important;

            &:hover {
                border-color: #4B5168 !important;
            }

            &:focus {
                border-color: #6C89E8 !important;
            }

            &:invalid {
                border-color: #D13E2C !important;
            }
        }

        .form-group>textarea {
            outline: none !important;
            resize: none;


            &::-webkit-scrollbar {
                border-radius: 8px !important;
                border: 1px solid #C6C8CE !important;
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px !important;
                background-color: #A8A8A8;
                border: 3px solid lightgrey;
                border-right: 2px solid lightgrey;
            }

            &::-webkit-scrollbar-track {
                background-color: lightgrey;
                border-radius: 8px;
            }
        }


        .formFocus::-webkit-scrollbar {
            display: none;
        }

        .invalidInputOne {
            color: #D13E2C;
            text-align: left;
            margin-bottom: 0px !important;
        }
    }

    .formcontrolPasswordWidget {
        width: 100%;

        .input-group {
            border: 1px solid #C6C8CE !important;
            border-radius: 8px !important;

            &:hover {
                border-color: #4B5168 !important;
            }

            &:focus {
                border-color: #6C89E8 !important;
            }

            &:invalid {
                border-color: #D13E2C !important;
            }
        }

        .form-control {
            height: 36px !important;
            border-right: none !important;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            padding: 8px 16px 8px 16px !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #1F2747 !important;
            border: none !important;


        }

        .formFocus::-webkit-scrollbar {
            display: none;
        }

        .invalidInputOne {
            color: #D13E2C;
            text-align: left;
            margin-bottom: 0px !important;
        }

        .input-group-text {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
            border-left: none !important;
            border: none !important;

        }
    }

}

.custom-control-height {
    height: 36px;
}

.custom-textarea-height {
    height: 116px;
}