.videoConferencing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    margin: auto 0;


    .videoHeadDiv {

        display: flex;
        flex-direction: column;
        gap: 16px;

        .videoHeading {
            display: flex;
            flex-direction: column;
            color: #1F2747;
            text-align: center;
            font-size: 32px;
            font-weight: 500;
        }

        .videoDes {
            color: #4B5168;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
        }
    }

    .fieldsDiv {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
    }

    .shareScreenVideo {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;

        .selectDiv {
            display: flex;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            gap: 16px;
            border-radius: 8px;

            .selectDivHead {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 4px;

                .selectDivHeadText {
                    color: #1F2747;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 14px;
                    display: flex;
                    flex-direction: column;
                }

                .selectDivHeadDes {
                    color: #4B5168;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                }
            }
        }
    }

    .videoConferenceButtons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
        width: 1034px;
        margin: auto;
        align-items: center;
    }
}