.sharingPanel {
    display: flex;
    flex-direction: row;
    width: 100%;

    .sharingPanelLeft {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .sharingPanelLeftBar {
            display: flex;
            height: 56px;
            padding: 10px 16px;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .sharingDivs{
                display: flex;
                flex-direction: row;
                gap: 8px;
            }

            .sharingButtons{
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }

    }

    .sharingPanelRight {
        display: flex;
        flex-direction: column;
    }

}

.overallPanelLayout{
    display: flex;
    flex-direction: column;
}