.gemstone-wrapper {
    display: flex;
    gap: 8px;
}

.appSelectItem {
    width: 160px;
    height: 140px;
    border: 1px solid #C6C8CE;
    box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.activeSelectItem {
        border: 1px solid #234FDC !important;

    }

    &:hover {
        // border: 1px solid #4B5168;
    }

    img {
        width: 48px !important;
        height: 48px !important;
    }

    svg {
        width: 32px !important;
        height: 32px !important;
    }

    .iamgeDiv {
        display: flex;
        padding: 21px 56px;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid var(--light-500, #C6C8CE);
        background: var(--light-100, #F7F9FA);
        overflow: hidden;
        height: 91px;
    }

    .activeImageDiv {
        border-bottom: 1px solid #234FDC !important;
        background-color: #DAE2F9 !important;
    }

    .imageLabel {
        color: #1F2747;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // margin: 0 auto;
        flex-grow: 1;
        background-color: #ffffff;
    }
}

.disabledDiv {
    opacity: 50%;

}

.enabledDiv {
    &:hover {
        border: 1px solid #4B5168;
    }
}