@import "~bootstrap/scss/bootstrap";

* {
    margin: 0;
    padding: 0;
}

:root {
    --primary-color: #476CE2;
    --primary-hover: #173cb5;
    --primary-dark: #1F2747;
    //--secondary-color: #575C70;
    --dark-gray: #333333;
    --master-gray: #6F7487;
    //--master-gray:#3B425D;
    --disabled-color: #C6C8CE;
    --light-gray: #dddddd;
    --gray62: #9a9a9a;
    --blue-color: #337ab7;
    //--blue-color: #234FDC;
    --dark-blue: #008DC4;
    --machineOffline: #8CA1A9;
    --machineOnline: #00b7ff;
    --dark-grayish-blue: #7b7c84;
    --white-smoke: #f5f5f5;
    --black: #000000;
    --strong-cyan: #00b4bd;
    --red-color: #E03400;
    --green-color: #39C696;
    --soft-peach: #f2dede;
    --warning: #ffc107;
    --dm-bg-color: #1f1f21;
    --dm-hover: #4064d9;
    --dm-primary-hover: #3e5ec9;
    --dm-color: #c0bdb8;
    --dm-active-color: rgb(105, 166, 213);
    --dm-border-color: #3e3d3d;
    // --text-muted: #B5B5C3;
    // primary-button: #234fdc;
    // primary-button-hover: #2343a8;

    overflow: hidden;
}

body,
html {
    // font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;


}